
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "plan-view",
  components: {},
  setup() {
    const current = ref<"month" | "annual">("month");

    const selected = ref("Startup");

    let plans = ref([]);

    onMounted(() => {
      //check if current user is authenticated
      ApiService.query("plans", { params: { plan_type: "1" } })
        .then(({ data }) => {
          console.log(data);
          plans.value = data.results;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    });

    return {
      plans,
      current,
      selected,
    };
  },
});
